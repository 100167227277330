import 'whatwg-fetch';

const apiRequest = (url, options, reqOpts) => {
	let error;

	return fetch(url, reqOpts)
		.then((response) => {
			if (!response.ok) {
				error = {
					status: response.status,
					statusText: response.statusText
				};
			}
			return response.json();
		}).then((response) => {
			if (error) {
				error.data = response;
				options.error(error);
			} else {
				options.success(response);
			}

			return response;
		});
};

const getDefaultReqOpts = () => {
	return {
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache-control': 'no-cache',
			'Pragma': 'no-cache'
		}
	};
};

const apiGet = (url, options) => {
	const reqOpts = getDefaultReqOpts();

	apiRequest(url, options, reqOpts);
};

function apiPut(url, options) {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'PUT';
	reqOpts.body = JSON.stringify(options.payload);

	apiRequest(url, options, reqOpts);
}

const apiPost = (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'POST';
	reqOpts.body = JSON.stringify(options.payload);

	apiRequest(url, options, reqOpts);
};

const apiDelete = (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'DELETE';

	return apiRequest(url, options, reqOpts);
};

// USERS
export const apiPostUser = options => apiPost('api/users', options);

export const apiPutUser = (id, options) => apiPut(`/api/user/${id}`, options);

export const apiUserSave = (options) => {
	if (options.payload.id) {
		return apiPutUser(options.payload.id, options);
	}

	return apiPostUser(options);
};

export const apiDeleteUser = (id, options) => apiDelete(`/api/user/${id}`, options);

export const apiGetUsers = options => apiGet('/api/users', options);

// VEHICLES

export const apiPostVehicle = options => apiPost('api/vehicles', options);

export const apiPutVehicle = (id, options) => apiPut(`/api/vehicle/${id}`, options);

export const apiVehicleSave = (options) => {
	if (options.payload.id) {
		return apiPutVehicle(options.payload.id, options);
	}

	return apiPostVehicle(options);
};

export const apiDeleteVehicle = (id, options) => apiDelete(`/api/vehicle/${id}`, options);

export const apiGetVehicles = options => apiGet('/api/vehicles', options);


// REFUELS

export const apiPostRefuel = options => apiPost('api/refuels', options);

export const apiPutRefuel = (id, options) => apiPut(`/api/refuel/${id}`, options);

export const apiRefuelSave = (options) => {
	if (options.payload.id) {
		return apiPutRefuel(options.payload.id, options);
	}

	return apiPostRefuel(options);
};

export const apiDeleteRefuel = (id, options) => apiDelete(`/api/refuel/${id}`, options);

export const apiGetRefuels = options => apiGet('/api/refuels', options);
export const apiGetCurrentRefuel = options => apiGet('/api/refuels/current', options);

// REPORT

export const apiPostReport = options => apiPost('api/reports', options);

export const apiPutReport = (id, options) => apiPut(`/api/report/${id}`, options);

export const apiReportSave = (options) => {
	if (options.payload.id) {
		return apiPutReport(options.payload.id, options);
	}

	return apiPostReport(options);
};

export const apiGetReports = (query, options) => apiGet(`/api/reports${query}`, options);
export const apiGetReportsSelf = options => apiGet('/api/reports/self', options);
export const apiDeleteReport = (id, options) => apiDelete(`/api/report/${id}`, options);

export const apiBulkDeleteReports = options => apiDelete(`/api/reports?ids=${encodeURIComponent(JSON.stringify(options.payload.ids))}`, options);
export const apiBulkUpdateReports = options => apiPut('/api/reports', options);

export const apiPutSettings = options => apiPut('/api/self/settings', options);


// OTHER

export const apiLogin = options => apiPost('/auth/login', options);
export const apiGetUserSelf = options => apiGet('/api/self', options);

