import React, { Component } from 'react';
import LoginComponent from '../components/login';
import { apiLogin } from '../api/index';

import './main.scss';

export default class LoginContainer extends Component {
	onLoginSubmit(payload) {
		apiLogin({
			payload,
			success: () => {
				window.location.href = '/';
			},
			error: (error) => {
				alert(`Ups! ${error.data.message}!`);
			}
		});
	}

	render() {
		return (<LoginComponent
			onSubmit={this.onLoginSubmit.bind(this)}
		/>);
	}
}
