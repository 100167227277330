import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export default class LoginComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailInput: '',
			passwordInput: ''
		};
	}

	componentDidMount() {
		document.addEventListener('keydown', (e) => {
			if (e.keyCode === 13) {
				this.onSubmit();
			}
		});
	}

	onSubmit() {
		const data = {
			email: this.state.emailInput,
			password: this.state.passwordInput
		};

		this.props.onSubmit(data);
	}

	render() {
		return (
			<div className="row">
				<div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4">
					<div className="login-panel panel panel-default">
						<div className="panel-heading">
							Pakker Avio
						</div>
						<div className="panel-body">
							<form role="form">
								<fieldset>
									<div className="form-group">
										<input
											onChange={(ev) => {
												this.setState({ emailInput: ev.target.value });
											}}
											value={this.state.emailInput}
											className="form-control"
											placeholder="E-mail"
											name="email"
											type="email"
											autoFocus={true}/>
									</div>
									<div className="form-group">
										<input
											onChange={(ev) => {
												this.setState({ passwordInput: ev.target.value });
											}}
											value={this.state.passwordInput}
											className="form-control"
											placeholder="Password"
											name="password"
											type="password"
										/>
									</div>
									<Button bsStyle="primary" onClick={this.onSubmit.bind(this)}> Logi sisse</Button>
								</fieldset>
							</form>
						</div>
					</div>
				</div>
			</div>);
	}
}
