import React from 'react';
import { render } from 'react-dom';
import Login from './containers/login';

import './libs/bootstrap';
import '../css/bootstrap.css';

render(
	<Login />,
	document.getElementById('root')
);
